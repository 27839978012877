import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { getVersion as getDesktopAppVersion } from '@tauri-apps/api/app';
import { relaunch } from '@tauri-apps/plugin-process';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { PRICING_PAGE_URL } from '../../../../shared/constants.platform';
import { getAuthedPathForDesktop } from '../../../../shared/foreground/auth.desktop';
import { globalState } from '../../../../shared/foreground/models';
import onLoggedOut from '../../../../shared/foreground/onLoggedOut.platform';
import { useProfileName, useProfileNameInitials } from '../../../../shared/foreground/stateHooks';
import { SubscriptionProduct } from '../../../../shared/types';
import { isDesktopApp } from '../../../../shared/utils/environment';
import getServerBaseUrl from '../../../../shared/utils/getServerBaseUrl.platform';
import { openURL } from '../../utils/openURL';
import { downloadAndInstallDesktopUpdate } from '../../utils/updates.desktop';
import useLocation from '../../utils/useLocation';
import ProfileIcon from '../icons/20StrokeProfile';
import { NavItemWithHover } from '../NavItem';
import styles from './AccountDropdown.module.css';
import { getSeparatorOption } from './docOptions';
import { Dropdown, DropdownOption, DropdownOptionType } from './Dropdown';

export default function AccountDropdown({ triggerClassName = '' }: { triggerClassName?: string }) {
  const [isOpen, setIsOpen] = useState(false);
  const profile = globalState(useCallback((state) => state.client.profile, []));
  const desktopPendingUpdate = globalState(useCallback((state) => state.desktopPendingUpdate, []));
  const subscription = useMemo(() => profile?.subscription, [profile]);
  const canUpgrade =
    subscription?.product !== undefined && subscription.product !== SubscriptionProduct.Full;
  const name = useProfileName();
  const nameInitials = useProfileNameInitials();
  const [desktopAppVersion, setDesktopAppVersion] = useState<string | undefined>();
  useEffect(() => {
    if (!isDesktopApp) {
      return;
    }
    (async () => {
      setDesktopAppVersion(await getDesktopAppVersion());
    })();
  }, []);

  const location = useLocation();
  const pathName = location.pathname;

  const pathNamesForActiveState = ['/profile'];
  const isActive = Boolean(
    pathNamesForActiveState.find((activeStates) => pathName.startsWith(activeStates)),
  );

  const options = useMemo(() => {
    const options: DropdownOption[] = [];
    options.push({
      type: DropdownOptionType.Node,
      node: (
        <Link to="/profile" className={styles.emailWrapper}>
          <p className={styles.profile}>Profile</p>
          <p className={styles.email}>{profile?.email}</p>
        </Link>
      ),
    });
    if (canUpgrade) {
      options.push({
        type: DropdownOptionType.Item,
        name: 'Upgrade your account',
        shortcut: '->',
        onSelect: () => {
          openURL(PRICING_PAGE_URL, '_blank');
        },
      });
    }
    if (isDesktopApp && (desktopAppVersion || desktopPendingUpdate)) {
      options.push(getSeparatorOption());
      if (desktopAppVersion) {
        options.push({
          type: DropdownOptionType.Title,
          name: `Reader Desktop v${desktopAppVersion}`,
        });
      }
      if (desktopPendingUpdate) {
        const newVersion = desktopPendingUpdate.update.version;
        switch (desktopPendingUpdate.status) {
          case 'readyForDownload':
            options.push({
              type: DropdownOptionType.Item,
              name: `Install version ${newVersion}`,
              async onSelect() {
                // this automatically kills & relaunches the app on windows, so no need to relaunch manually.
                await downloadAndInstallDesktopUpdate(desktopPendingUpdate.update);
              },
            });
            break;
          case 'readyForInstall':
            options.push({
              type: DropdownOptionType.Item,
              name: `Upgrade to version ${newVersion}`,
              onSelect() {
                relaunch();
              },
            });
            break;
          case 'downloading':
            options.push({
              type: DropdownOptionType.Title,
              name: 'Downloading update...',
            });
            break;
          case 'failed':
            options.push({
              type: DropdownOptionType.Title,
              name: 'Update failed.',
            });
            break;
        }
      }
    }
    options.push({
      type: DropdownOptionType.Item,
      name: 'Log out',
      onSelect: async () => {
        await onLoggedOut();
        const next = isDesktopApp ? encodeURIComponent(await getAuthedPathForDesktop()) : '/read';
        window.location.href = `${getServerBaseUrl()}/logout?next=${next}`;
      },
    });
    return options;
  }, [canUpgrade, desktopPendingUpdate, profile?.email, desktopAppVersion]);

  return (
    <div className={styles.dropdownWrapper}>
      <Dropdown
        appendToDocumentBody
        isOpen={isOpen}
        options={options}
        setIsOpen={setIsOpen}
        side="right"
        contentAlignment="end"
        contentClassName={styles.dropdownContent}
        trigger={
          <DropdownMenu.Trigger asChild>
            <NavItemWithHover
              left={
                nameInitials ? (
                  <div className={styles.initials}>{nameInitials}</div>
                ) : (
                  <ProfileIcon className={styles.profileIcon} />
                )
              }
              name={name ?? 'Account'}
              nameClassName={styles.name}
              isActive={isActive}
            />
          </DropdownMenu.Trigger>
        }
      />
    </div>
  );
}
